import { Validate } from '@edgvr-front/util/validate';
import { CompanyHostDeviceId, CompanyName } from '../interface';
import { companyHostDeviceId, companyName } from '../schema';

export class CompanyHostDeviceEnableVO {
  private static configValidation = Validate.compile(
    CompanyHostDeviceEnableVO.configValidationSchema()
  );

  constructor(readonly value: CompanyHostDeviceEnable) {
    Validate.assert(CompanyHostDeviceEnableVO.configValidation, this.value);
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['companyName', 'deviceId'],
      properties: {
        companyName,
        deviceId: companyHostDeviceId,
      },
      additionalProperties: false,
    };
  }
}

interface CompanyHostDeviceEnable {
  companyName: CompanyName;
  deviceId: CompanyHostDeviceId;
}
