import { Timestamp, timestamp } from '@edgvr-front/common/domain';
import {
  CompanyHostDeviceSid,
  companyHostDeviceSid,
  CompanyName,
  companyName,
} from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';
import { WorkshopName, workshopName } from '@edgvr-front/workshops/domain';

export class CompanyUsageGenerateRequestDTO {
  private static configValidation = Validate.compile(
    CompanyUsageGenerateRequestDTO.configValidationSchema()
  );

  constructor(readonly value: CompanyUsageGenerateRequest) {
    Validate.assert(
      CompanyUsageGenerateRequestDTO.configValidation,
      this.value
    );
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['companyName', 'dateFrom'],
      properties: {
        companyName,
        dateFrom: timestamp,
        dateTill: timestamp,
        companyHostDeviceSid,
        workshopName,
      },
      additionalProperties: false,
    };
  }
}

interface CompanyUsageGenerateRequest {
  companyName: CompanyName;
  dateFrom: Timestamp;
  dateTill?: Timestamp;
  companyHostDeviceSid?: CompanyHostDeviceSid;
  workshopName?: WorkshopName;
}
