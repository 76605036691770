import { CompanyName, companyName } from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyHostDevicesListRequestDTO {
  private static validation = Validate.compile(
    CompanyHostDevicesListRequestDTO.validationSchema()
  );

  constructor(readonly data: CompanyHostDevicesListRequest) {
    Validate.assert(CompanyHostDevicesListRequestDTO.validation, data);
  }

  private static validationSchema() {
    return {
      type: 'object',
      required: ['companyName'],
      properties: {
        companyName,
      },
    };
  }
}

interface CompanyHostDevicesListRequest {
  companyName: CompanyName;
}
