import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EdgvrFrontFeatureHeaderModule } from '@edgvr-front/edgvr-front/feature/header';
import Components from './components';

@NgModule({
  declarations: [...Components],
  imports: [CommonModule, RouterModule, EdgvrFrontFeatureHeaderModule],
  exports: [...Components],
})
export class EdgvrFrontFeatureTemplateModule {}
