import {
  CompanyGeneratedUsageJson,
  CompanyGeneratedUsageVO,
  CompanyHostDeviceEntity,
  CompanyHostDeviceEntityJson,
  CompanyStudentDeviceEntity,
  CompanyStudentDeviceEntityJson,
} from '@edgvr-front/companies/domain';

export function buildCompanyHostDeviceEntity(
  companyHostDeviceEntityJson: CompanyHostDeviceEntityJson
): CompanyHostDeviceEntity {
  const { id, title, sid, model, enabled } = companyHostDeviceEntityJson;

  return new CompanyHostDeviceEntity({
    id,
    title,
    sid,
    model,
    enabled,
  });
}

export function buildCompanyStudentDeviceEntity(
  companyStudentDeviceEntityJson: CompanyStudentDeviceEntityJson
): CompanyStudentDeviceEntity {
  const { id, title, sid, model } = companyStudentDeviceEntityJson;

  return new CompanyStudentDeviceEntity({
    id,
    title,
    sid,
    model,
  });
}

export function buildCompanyGeneratedUsageVO(
  companyGeneratedUsageJson: CompanyGeneratedUsageJson
): CompanyGeneratedUsageVO {
  return new CompanyGeneratedUsageVO(companyGeneratedUsageJson);
}
