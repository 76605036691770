import { CompanyName, companyName } from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyStudentDevicesListRequestDTO {
  private static validation = Validate.compile(
    CompanyStudentDevicesListRequestDTO.validationSchema()
  );

  constructor(readonly data: CompanyStudentDevicesListRequest) {
    Validate.assert(CompanyStudentDevicesListRequestDTO.validation, data);
  }

  private static validationSchema() {
    return {
      type: 'object',
      required: ['companyName'],
      properties: {
        companyName,
      },
    };
  }
}

interface CompanyStudentDevicesListRequest {
  companyName: CompanyName;
}
