import { CompanyStudentDeviceEntity } from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyStudentDevicesListResponseDTO {
  private static validation = Validate.compile(
    CompanyStudentDevicesListResponseDTO.validationSchema(),
    CompanyStudentDeviceEntity
  );

  constructor(readonly data: CompanyStudentDevicesListResponse) {
    Validate.assert(CompanyStudentDevicesListResponseDTO.validation, data);
  }

  private static validationSchema() {
    return {
      type: 'object',
      required: ['devices'],
      properties: {
        devices: {
          type: 'array',
          minItems: 0,
          maxItems: Number.MAX_SAFE_INTEGER,
          items: {
            instanceof: CompanyStudentDeviceEntity.name,
          },
        },
      },
      additionalProperties: false,
    };
  }
}

interface CompanyStudentDevicesListResponse {
  devices: CompanyStudentDeviceEntity[];
}
