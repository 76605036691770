import { Timestamp, timestamp } from '@edgvr-front/common/domain';
import { Validate } from '@edgvr-front/util/validate';
import { workshopName, WorkshopName } from '@edgvr-front/workshops/domain';
import { CompanyHostDeviceSid } from '../interface';
import { companyHostDeviceSid } from '../schema';

export class CompanyUsageGenerate1Cmd {
  private static configValidation = Validate.compile(
    CompanyUsageGenerate1Cmd.configValidationSchema()
  );

  constructor(readonly value: CompanyUsageGenerate1) {
    Validate.assert(CompanyUsageGenerate1Cmd.configValidation, this.value);
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['dateFrom'],
      properties: {
        companyHostDeviceSid,
        dateFrom: timestamp,
        dateTill: timestamp,
        workshopName,
      },
      additionalProperties: false,
    };
  }
}

interface CompanyUsageGenerate1 {
  dateFrom: Timestamp;
  dateTill?: Timestamp;
  companyHostDeviceSid?: CompanyHostDeviceSid;
  workshopName?: WorkshopName;
}
