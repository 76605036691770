import { CompanyGeneratedUsageVO } from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyUsageGenerateResponseDTO {
  private static configValidation = Validate.compile(
    CompanyUsageGenerateResponseDTO.configValidationSchema(),
    CompanyGeneratedUsageVO
  );

  constructor(readonly value: CompanyUsageGenerateResponse) {
    Validate.assert(
      CompanyUsageGenerateResponseDTO.configValidation,
      this.value
    );
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['companyGeneratedUsageVO'],
      properties: {
        companyGeneratedUsageVO: {
          instanceof: CompanyGeneratedUsageVO.name,
        },
      },
      additionalProperties: false,
    };
  }
}

interface CompanyUsageGenerateResponse {
  companyGeneratedUsageVO: CompanyGeneratedUsageVO;
}
