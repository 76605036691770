import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccountDataAccessModule } from '@edgvr-front/account/data-access';
import { AccountFeatureAccountAccessModule } from '@edgvr-front/account/feature/account-access';
import { CompaniesUtilCompanyUrlModule } from '@edgvr-front/companies/util/company-url';
import { UiModule } from '@edgvr-front/ui';
import Components from './components';

@NgModule({
  declarations: [...Components],
  imports: [
    CommonModule,
    RouterModule,
    AccountDataAccessModule,
    AccountFeatureAccountAccessModule,
    CompaniesUtilCompanyUrlModule,
    UiModule,
  ],
  exports: [...Components],
})
export class EdgvrFrontFeatureHeaderModule {}
