import { CompanyHostDeviceEntity } from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyHostDevicesListResponseDTO {
  private static validation = Validate.compile(
    CompanyHostDevicesListResponseDTO.validationSchema(),
    CompanyHostDeviceEntity
  );

  constructor(readonly data: CompanyHostDevicesListResponse) {
    Validate.assert(CompanyHostDevicesListResponseDTO.validation, data);
  }

  private static validationSchema() {
    return {
      type: 'object',
      required: ['devices'],
      properties: {
        devices: {
          type: 'array',
          minItems: 0,
          maxItems: Number.MAX_SAFE_INTEGER,
          items: {
            instanceof: CompanyHostDeviceEntity.name,
          },
        },
      },
      additionalProperties: false,
    };
  }
}

interface CompanyHostDevicesListResponse {
  devices: CompanyHostDeviceEntity[];
}
