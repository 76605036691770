import { Companies } from '../constant';

export const companyName = {
  type: 'string',
  // Subdomain limitations
  // https://stackoverflow.com/a/7933253
  pattern: '^[a-z0-9](?:[a-z0-9\\-]{0,61}[a-z0-9])$',
  minLength: 1,
  maxLength: 63,
};

export const newCompanyName = {
  ...companyName,
  // exclude reserved company name
  not: { enum: [Companies.Admin] },
};
