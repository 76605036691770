import { CompanyHostDeviceEntityJson } from '@edgvr-front/companies/domain';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { CompanyHostDevicesActions } from '../actions';

export const COMPANY_HOST_DEVICES_FEATURE_KEY = 'company-host-devices';

export interface CompanyHostDevicesState
  extends EntityState<CompanyHostDeviceEntityJson> {
  isLoading: boolean;
}

export const companyHostDevicesAdapter: EntityAdapter<CompanyHostDeviceEntityJson> =
  createEntityAdapter<CompanyHostDeviceEntityJson>({
    sortComparer: (
      lhs: CompanyHostDeviceEntityJson,
      rhs: CompanyHostDeviceEntityJson
    ) => lhs.id - rhs.id,
    selectId: (_) => _.id,
  });

export const initialCompanyHostDevicesState: CompanyHostDevicesState =
  companyHostDevicesAdapter.getInitialState({
    isLoading: false,
  });

const reducer = createReducer(
  initialCompanyHostDevicesState,
  on(CompanyHostDevicesActions.companyHostDeviceDelete, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    CompanyHostDevicesActions.companyHostDeviceDeleteSuccess,
    (state, { companyHostDeviceDeleteVO }) =>
      companyHostDevicesAdapter.removeOne(
        companyHostDeviceDeleteVO.value.deviceId,
        { ...state, isLoading: false }
      )
  ),
  on(CompanyHostDevicesActions.companyHostDeviceDeleteFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(CompanyHostDevicesActions.companyHostDeviceDisable, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    CompanyHostDevicesActions.companyHostDeviceDisableSuccess,
    (state, { device }) =>
      companyHostDevicesAdapter.setOne(device.toJSON(), {
        ...state,
        isLoading: false,
      })
  ),
  on(CompanyHostDevicesActions.companyHostDeviceDisableFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(CompanyHostDevicesActions.companyHostDeviceEnable, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    CompanyHostDevicesActions.companyHostDeviceEnableSuccess,
    (state, { device }) =>
      companyHostDevicesAdapter.setOne(device.toJSON(), {
        ...state,
        isLoading: false,
      })
  ),
  on(CompanyHostDevicesActions.companyHostDeviceEnableFailure, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(CompanyHostDevicesActions.companyHostDevicesList, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    CompanyHostDevicesActions.companyHostDevicesListSuccess,
    (state, { devices }) =>
      companyHostDevicesAdapter.setAll(
        devices.map((_) => _.toJSON()),
        {
          ...state,
          isLoading: false,
        }
      )
  ),
  on(CompanyHostDevicesActions.companyHostDevicesListFailure, (state) => ({
    ...state,
    isLoading: false,
  }))
);

export function companyHostDevicesReducer(
  state: CompanyHostDevicesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
