import { Validate } from '@edgvr-front/util/validate';
import { CompanyName } from '../interface';
import { companyName } from '../schema';
import { CompanyUsageGenerate1Cmd } from './CompanyUsageGenerate1Cmd';

export class CompanyUsageGenerate2Cmd {
  private static configValidation = Validate.compile(
    CompanyUsageGenerate2Cmd.configValidationSchema(),
    CompanyUsageGenerate1Cmd
  );

  constructor(readonly value: CompanyUsageGenerate2) {
    Validate.assert(CompanyUsageGenerate2Cmd.configValidation, this.value);
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['companyName', 'companyUsageGenerateCmd'],
      properties: {
        companyName,
        companyUsageGenerateCmd: {
          instanceof: CompanyUsageGenerate1Cmd.name,
        },
      },
      additionalProperties: false,
    };
  }
}

interface CompanyUsageGenerate2 {
  companyName: CompanyName;
  companyUsageGenerateCmd: CompanyUsageGenerate1Cmd;
}
