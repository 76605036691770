import { Validate } from '@edgvr-front/util/validate';
import { CompanyName, CompanyStudentDeviceId } from '../interface';
import { companyName, companyStudentDeviceId } from '../schema';

export class CompanyStudentDeviceDeleteVO {
  private static configValidation = Validate.compile(
    CompanyStudentDeviceDeleteVO.configValidationSchema()
  );

  constructor(readonly value: CompanyStudentDeviceDelete) {
    Validate.assert(CompanyStudentDeviceDeleteVO.configValidation, this.value);
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['companyName', 'deviceId'],
      properties: {
        companyName,
        deviceId: companyStudentDeviceId,
      },
      additionalProperties: false,
    };
  }
}

interface CompanyStudentDeviceDelete {
  companyName: CompanyName;
  deviceId: CompanyStudentDeviceId;
}
