import {
  CompanyHostDeviceId,
  CompanyName,
  companyHostDeviceId,
  companyName,
} from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyHostDeviceDisableRequestDTO {
  private static validation = Validate.compile(
    CompanyHostDeviceDisableRequestDTO.validationSchema()
  );

  constructor(readonly data: CompanyHostDeviceDisableRequest) {
    Validate.assert(CompanyHostDeviceDisableRequestDTO.validation, data);
  }

  private static validationSchema() {
    return {
      type: 'object',
      required: ['companyName', 'deviceId'],
      properties: {
        companyName,
        deviceId: companyHostDeviceId,
      },
    };
  }
}

interface CompanyHostDeviceDisableRequest {
  companyName: CompanyName;
  deviceId: CompanyHostDeviceId;
}
