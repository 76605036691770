import { CompanyStudentDeviceEntityJson } from '@edgvr-front/companies/domain';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { CompanyStudentDevicesActions } from '../actions';

export const COMPANY_STUDENT_DEVICES_FEATURE_KEY = 'company-student-devices';

export interface CompanyStudentDevicesState
  extends EntityState<CompanyStudentDeviceEntityJson> {
  isLoading: boolean;
}

export const companyStudentDevicesAdapter: EntityAdapter<CompanyStudentDeviceEntityJson> =
  createEntityAdapter<CompanyStudentDeviceEntityJson>({
    sortComparer: (
      lhs: CompanyStudentDeviceEntityJson,
      rhs: CompanyStudentDeviceEntityJson
    ) => lhs.id - rhs.id,
    selectId: (_) => _.id,
  });

export const initialCompanyStudentDevicesState: CompanyStudentDevicesState =
  companyStudentDevicesAdapter.getInitialState({
    isLoading: false,
  });

const reducer = createReducer(
  initialCompanyStudentDevicesState,
  on(CompanyStudentDevicesActions.companyStudentDeviceDelete, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    CompanyStudentDevicesActions.companyStudentDeviceDeleteSuccess,
    (state, { companyStudentDeviceDeleteVO }) =>
      companyStudentDevicesAdapter.removeOne(
        companyStudentDeviceDeleteVO.value.deviceId,
        {
          ...state,
          isLoading: false,
        }
      )
  ),
  on(
    CompanyStudentDevicesActions.companyStudentDeviceDeleteFailure,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(CompanyStudentDevicesActions.companyStudentDevicesList, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    CompanyStudentDevicesActions.companyStudentDevicesListSuccess,
    (state, { devices }) =>
      companyStudentDevicesAdapter.setAll(
        devices.map((_) => _.toJSON()),
        {
          ...state,
          isLoading: false,
        }
      )
  ),
  on(
    CompanyStudentDevicesActions.companyStudentDevicesListFailure,
    (state) => ({
      ...state,
      isLoading: false,
    })
  )
);

export function companyStudentDevicesReducer(
  state: CompanyStudentDevicesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
