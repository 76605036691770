<header class="light">
  <a [routerLink]="['/']" class="logo me-5">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="37"
      fill="none"
      viewBox="0 0 88 37"
    >
      <path
        fill="#F4362D"
        d="M79.202 9.639h-2.535l-1.95 6.747h-.026l-1.937-6.747h-2.522l3.211 9.62h2.535l3.224-9.62Zm8.485 9.62-2.613-3.276c1.521-.247 2.392-1.547 2.392-3.03 0-1-.39-1.988-1.183-2.625-.832-.663-1.95-.69-2.964-.69h-3.042v9.62h2.353v-3.106h.026l2.223 3.107h2.808Zm-2.574-6.162c0 .988-.689 1.378-1.612 1.378h-.87v-2.678h.896c.845 0 1.586.35 1.586 1.3Z"
      />
      <path
        fill="#484F53"
        d="M19.94 19.175c0-5.747-4.2-10.426-10.004-10.426C4.37 8.749 0 13.463 0 19.003 0 24.578 4.507 29.12 10.039 29.12c4.268 0 7.614-2.787 9.253-6.607h-5.805c-.922 1.17-1.912 1.79-3.448 1.79-2.22 0-4.098-1.343-4.44-3.614h14.205c.102-.516.136-.998.136-1.514Zm-5.668-2.34H5.668c.444-2.065 2.254-3.269 4.302-3.269 2.049 0 3.858 1.204 4.302 3.27ZM42.834 28.5V0h-5.668v11.02c-1.537-1.583-3.38-2.271-5.566-2.271-5.702 0-9.39 4.61-9.39 10.15 0 5.644 3.688 10.22 9.493 10.22 2.22 0 4.473-.653 5.668-2.683h.068V28.5h5.395Zm-5.36-9.635c0 2.822-2.015 4.921-4.85 4.921-2.833 0-4.746-2.133-4.746-4.92 0-2.788 2.083-4.784 4.815-4.784 2.731 0 4.78 2.03 4.78 4.783ZM66.573 26.195V9.402h-5.668v1.79h-.069c-1.092-1.755-3.278-2.41-5.224-2.41-5.77 0-9.56 4.68-9.56 10.29 0 5.574 3.892 10.048 9.56 10.048 2.015 0 4.2-.86 5.19-2.719h.068v.585c0 3.029-1.502 5.196-4.61 5.196-1.399 0-2.697-.516-3.311-1.892h-6.556c.137 1.067.546 2.065 1.161 2.96 1.878 2.752 5.088 3.75 8.297 3.75 3.449 0 7.717-1.308 9.595-4.508 1.092-1.892 1.127-4.164 1.127-6.297Zm-5.668-7.158c0 2.753-1.913 4.75-4.644 4.75-2.732 0-4.541-2.272-4.541-4.922 0-2.58 1.946-4.748 4.575-4.748 2.8 0 4.61 2.168 4.61 4.92Z"
      />
    </svg>
  </a>
  <div class="company-selector-wrap" *ngIf="accounts$ | async as accounts">
    <svg
      class="company-selector-refresh"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      (click)="onReSignIn(); (false)"
    >
      <g fill="#ED4C39" clip-path="url(#clip0_1150_3997)">
        <path
          d="M5.342 10.074a.443.443 0 0 0-.334-.734H3.87A5.147 5.147 0 0 1 9 3.858c1.362 0 2.602.534 3.523 1.402l1.605-1.877A7.55 7.55 0 0 0 9 1.394a7.556 7.556 0 0 0-5.378 2.228 7.556 7.556 0 0 0-2.22 5.718H.444a.443.443 0 0 0-.334.734l2.162 2.48.454.52 1.63-1.87.986-1.13ZM17.89 8.627l-1.612-1.85-1.004-1.15-1.433 1.643-1.183 1.357a.443.443 0 0 0 .334.734h1.136A5.148 5.148 0 0 1 9 14.141a5.115 5.115 0 0 1-3.147-1.078L4.25 14.94A7.548 7.548 0 0 0 9 16.606a7.556 7.556 0 0 0 5.378-2.228 7.551 7.551 0 0 0 2.22-5.017h.958c.38 0 .584-.447.334-.734Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1150_3997">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
    <div class="company-selector" *ngIf="selectedAccount">
      <div class="company-selector-label">Select company</div>
      <a
        [href]="selectedAccount | companyUrl"
        class="company-selector-value"
        target="_blank"
        >{{ selectedAccount }}</a
      >
    </div>
    <select (change)="onSelect($event)">
      <option [value]="account" *ngFor="let account of accounts">
        {{ account }}
      </option>
    </select>
  </div>
  <div class="ms-auto">
    <ng-container *edgvrFrontAccountAnonAccess>
      <a
        [routerLink]="['/', 'sign-in']"
        routerLinkActive="active"
        class="inner-link"
        >Sign in</a
      >
    </ng-container>

    <ng-container *edgvrFrontAccountAccess>
      <ng-container *ngIf="email$ | async as email; else loading">
        <a>Hi, {{ email }}</a>
      </ng-container>
      <ng-template #loading>
        <edgvr-front-ui-loader
          [isLoading]="true"
          [loaderOnly]="true"
          [dark]="true"
        ></edgvr-front-ui-loader>
      </ng-template>
      <a class="btn btn-alt" (click)="onSignOut(); (false)">Sign out</a>
    </ng-container>

    <div class="install-wrap">
      <a [href]="installer" target="_blank" *edgvrFrontAccountAccess>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          fill="none"
          viewBox="0 0 56 56"
        >
          <g filter="url(#filter0_d_1178_4085)">
            <path
              fill="#fff"
              d="M8 12.667A6.667 6.667 0 0 1 14.667 6h26.666A6.667 6.667 0 0 1 48 12.667v26.666A6.667 6.667 0 0 1 41.333 46H14.667A6.667 6.667 0 0 1 8 39.333V12.667Z"
            />
          </g>
          <rect
            width="30.833"
            height="9.583"
            x="12.583"
            y="31.833"
            fill="#E6332A"
            rx="2"
          />
          <path
            fill="#E6332A"
            stroke="#fff"
            d="M33.604 26.129a.27.27 0 0 0-.247-.183h-2.938v-2.359c0-.163-.12-.295-.268-.295h-4.29c-.148 0-.269.132-.269.295v2.359h-2.949a.27.27 0 0 0-.248.182.319.319 0 0 0 .058.323l5.35 5.92c.05.056.118.087.19.087.07 0 .139-.031.189-.086l5.365-5.92a.318.318 0 0 0 .057-.323Z"
          />
          <path
            fill="#3A3A3A"
            d="M17.61 15.91c0-1.438-1.014-2.61-2.417-2.61-1.345 0-2.401 1.18-2.401 2.568 0 1.396 1.089 2.533 2.426 2.533 1.031 0 1.84-.698 2.236-1.654h-1.403c-.223.293-.462.448-.833.448-.537 0-.99-.336-1.073-.905h3.433c.025-.13.033-.25.033-.38Zm-1.369-.585h-2.08c.108-.517.545-.819 1.04-.819s.933.302 1.04.819ZM23.144 18.246v-7.454h-1.37v3.076c-.371-.396-.817-.569-1.345-.569-1.378 0-2.27 1.155-2.27 2.543 0 1.413.892 2.56 2.295 2.56.536 0 1.08-.164 1.37-.673h.016v.517h1.304Zm-1.296-2.413c0 .707-.487 1.233-1.172 1.233-.684 0-1.147-.535-1.147-1.233 0-.698.504-1.198 1.164-1.198.66 0 1.155.509 1.155 1.198ZM28.881 17.669v-4.206h-1.37v.448h-.016c-.264-.44-.793-.603-1.263-.603-1.395 0-2.31 1.172-2.31 2.577 0 1.396.94 2.516 2.31 2.516.487 0 1.015-.215 1.254-.68h.017v.146c0 .758-.363 1.301-1.114 1.301-.338 0-.652-.129-.8-.474h-1.585c.033.268.132.518.28.742.454.689 1.23.939 2.006.939.833 0 1.865-.328 2.319-1.129.264-.474.272-1.043.272-1.577Zm-1.37-1.793c0 .69-.462 1.19-1.122 1.19-.66 0-1.098-.57-1.098-1.233 0-.646.47-1.19 1.106-1.19.677 0 1.114.544 1.114 1.233Z"
          />
          <path
            fill="#E6332A"
            d="M34.782 13.671c0-.709-.259-1.372-.777-1.83-.546-.476-1.36-.504-2.039-.504h-2.163v6.91h1.618V16.08h.733c.742 0 1.359-.074 1.931-.597.465-.43.697-1.177.697-1.812Zm-1.618.029c0 .7-.5.83-1.055.83h-.688v-1.643h.626c.554 0 1.117.094 1.117.813ZM37.712 14.446v-1.559c-.572 0-.983.196-1.242.729h-.018v-.551h-1.395v5.181h1.484V15.4c0-.803.519-.934 1.171-.953ZM43 15.65c0-1.586-1.153-2.753-2.673-2.753s-2.673 1.167-2.673 2.754 1.153 2.754 2.673 2.754S43 17.238 43 15.651Zm-1.484 0c0 .701-.5 1.308-1.189 1.308-.688 0-1.189-.607-1.189-1.307s.5-1.307 1.189-1.307c.688 0 1.189.607 1.189 1.307Z"
          />
          <path
            fill="#fff"
            d="M16.886 38.917h-1.18v-4.625h1.18v4.625ZM21.75 38.917h-1.025l-1.762-2.813-.013.007.032 2.806H17.85v-4.625h1.02l1.787 2.906.012-.013-.05-2.893h1.131v4.625ZM22.455 37.386h1.175c0 .374.263.63.644.63.387 0 .619-.193.619-.443 0-.406-.513-.481-1.119-.706-.663-.244-1.244-.575-1.244-1.369 0-.894.831-1.294 1.775-1.294.869 0 1.669.394 1.713 1.438h-1.169c-.044-.288-.212-.45-.594-.45-.35 0-.55.106-.55.343 0 .32.45.438.963.62.893.312 1.393.668 1.393 1.487 0 .912-.668 1.362-1.774 1.362-1.1 0-1.832-.462-1.832-1.468v-.15ZM29.405 35.33h-.906v3.587h-1.181v-3.588h-.913v-1.037h3v1.037ZM34.109 38.917h-1.281l-.225-.669h-1.607l-.243.669h-1.269l1.856-4.625h.932l1.837 4.625ZM32.29 37.31l-.475-1.58h-.018l-.476 1.58h.97ZM37.289 38.917h-2.55v-4.625h1.181v3.587h1.369v1.038ZM40.46 38.917h-2.55v-4.625h1.18v3.587h1.37v1.038Z"
          />
          <defs>
            <filter
              id="filter0_d_1178_4085"
              width="56"
              height="56"
              x="0"
              y="0"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1178_4085"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_1178_4085"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </a>
      <a
        [href]="moduleBuilder"
        target="_blank"
        *edgvrFrontAccountAdminCompanyAccess
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          fill="none"
          viewBox="0 0 56 56"
        >
          <g filter="url(#filter0_d_1178_4150)">
            <path
              fill="#fff"
              d="M8 12.667A6.667 6.667 0 0 1 14.667 6h26.666A6.667 6.667 0 0 1 48 12.667v26.666A6.667 6.667 0 0 1 41.333 46H14.667A6.667 6.667 0 0 1 8 39.333V12.667Z"
            />
          </g>
          <rect
            width="30.833"
            height="9.583"
            x="12.583"
            y="31.833"
            fill="#E6332A"
            rx="2"
          />
          <path
            fill="#fff"
            d="M19.932 37.65c0 .354-.132.732-.408.966-.456.39-.876.384-1.452.384h-1.41v-4.44h1.368c.534 0 1.038.006 1.422.432.186.204.264.456.264.732 0 .378-.186.708-.516.894.486.168.732.528.732 1.032Zm-1.302-1.758c0-.282-.21-.336-.444-.336h-.438v.69h.432c.246 0 .45-.072.45-.354Zm.216 1.686c0-.318-.264-.384-.528-.384h-.57v.81h.564c.282 0 .534-.096.534-.426Zm5.096-.336c0 .528-.06.978-.444 1.362-.335.342-.768.48-1.242.48-.462 0-.954-.132-1.277-.474-.402-.426-.444-.804-.444-1.368V34.56h1.134v2.682c0 .402.066.846.575.846.535 0 .564-.426.564-.846V34.56h1.134v2.682ZM25.87 39h-1.134v-4.44h1.134V39Zm3.283 0h-2.448v-4.44h1.134v3.444h1.314V39Zm4.226-2.232c0 1.458-.834 2.232-2.286 2.232H29.54v-4.44h1.47c.804 0 1.458.132 1.962.828.288.402.408.888.408 1.38Zm-1.134.018c0-.708-.324-1.23-1.08-1.23h-.492v2.448h.486c.774 0 1.086-.498 1.086-1.218ZM36.518 39h-2.466v-4.44h2.466v.996h-1.38v.69h1.338v.996h-1.338v.762h1.38V39Zm4.124 0h-1.296l-1.026-1.434h-.012V39h-1.086v-4.44h1.404c.468 0 .984.012 1.368.318.366.294.546.75.546 1.212 0 .684-.402 1.284-1.104 1.398L40.642 39Zm-1.188-2.844c0-.438-.342-.6-.732-.6h-.414v1.236h.402c.426 0 .744-.18.744-.636Z"
          />
          <path
            fill="#E6332A"
            stroke="#fff"
            d="M33.604 26.129a.27.27 0 0 0-.247-.183h-2.938v-2.359c0-.163-.12-.295-.268-.295h-4.29c-.148 0-.269.132-.269.295v2.359h-2.949a.27.27 0 0 0-.248.182.319.319 0 0 0 .058.323l5.35 5.92c.05.056.118.087.19.087.07 0 .139-.031.189-.086l5.365-5.92a.318.318 0 0 0 .057-.323Z"
          />
          <path
            fill="#3A3A3A"
            d="M17.61 15.91c0-1.438-1.014-2.61-2.417-2.61-1.345 0-2.401 1.18-2.401 2.568 0 1.396 1.089 2.533 2.426 2.533 1.031 0 1.84-.698 2.236-1.654h-1.403c-.222.293-.462.448-.833.448-.537 0-.99-.336-1.073-.905h3.433c.025-.13.033-.25.033-.38Zm-1.369-.585h-2.08c.108-.517.545-.819 1.04-.819s.933.302 1.04.819ZM23.144 18.246v-7.454h-1.37v3.076c-.371-.396-.817-.569-1.345-.569-1.378 0-2.27 1.155-2.27 2.543 0 1.413.892 2.56 2.295 2.56.536 0 1.08-.164 1.37-.673h.016v.517h1.304Zm-1.296-2.413c0 .707-.487 1.233-1.172 1.233-.684 0-1.147-.535-1.147-1.233 0-.698.504-1.198 1.164-1.198.66 0 1.155.509 1.155 1.198ZM28.881 17.669v-4.206h-1.37v.448h-.016c-.264-.44-.793-.603-1.263-.603-1.395 0-2.31 1.172-2.31 2.577 0 1.396.94 2.516 2.31 2.516.487 0 1.015-.215 1.254-.68h.017v.146c0 .758-.363 1.301-1.114 1.301-.338 0-.652-.129-.8-.474h-1.585c.033.268.132.518.28.742.454.689 1.23.939 2.006.939.833 0 1.865-.328 2.319-1.129.264-.474.272-1.043.272-1.577Zm-1.37-1.793c0 .69-.462 1.19-1.122 1.19-.66 0-1.098-.57-1.098-1.233 0-.646.47-1.19 1.106-1.19.677 0 1.114.544 1.114 1.233Z"
          />
          <path
            fill="#E6332A"
            d="M34.782 13.671c0-.709-.259-1.372-.777-1.83-.546-.476-1.36-.504-2.039-.504h-2.163v6.91h1.618V16.08h.733c.742 0 1.36-.074 1.931-.597.465-.43.697-1.177.697-1.812Zm-1.618.029c0 .7-.5.83-1.055.83h-.688v-1.643h.626c.554 0 1.117.094 1.117.813ZM37.712 14.446v-1.559c-.572 0-.983.196-1.242.729h-.018v-.551h-1.395v5.181h1.484V15.4c0-.803.519-.934 1.171-.953ZM43 15.65c0-1.586-1.153-2.753-2.673-2.753s-2.673 1.167-2.673 2.754 1.153 2.754 2.673 2.754S43 17.238 43 15.651Zm-1.484 0c0 .701-.5 1.308-1.189 1.308-.688 0-1.189-.607-1.189-1.307s.5-1.307 1.189-1.307c.688 0 1.189.607 1.189 1.307Z"
          />
          <defs>
            <filter
              id="filter0_d_1178_4150"
              width="56"
              height="56"
              x="0"
              y="0"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1178_4150"
              />
              <feBlend
                in="SourceGraphic"
                in2="effect1_dropShadow_1178_4150"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </a>
    </div>
    <a [href]="'mailto:' + contact" class="btn">Contact</a>
  </div>
</header>
