import {
  CompanyHostDeviceId,
  CompanyName,
  companyHostDeviceId,
  companyName,
} from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyHostDeviceDeleteRequestDTO {
  private static validation = Validate.compile(
    CompanyHostDeviceDeleteRequestDTO.validationSchema()
  );

  constructor(readonly data: CompanyHostDeviceDeleteRequest) {
    Validate.assert(CompanyHostDeviceDeleteRequestDTO.validation, data);
  }

  private static validationSchema() {
    return {
      type: 'object',
      required: ['companyName', 'deviceId'],
      properties: {
        companyName,
        deviceId: companyHostDeviceId,
      },
    };
  }
}

interface CompanyHostDeviceDeleteRequest {
  companyName: CompanyName;
  deviceId: CompanyHostDeviceId;
}
