import { buildCompanyHostDeviceEntity } from '@edgvr-front/companies/util/factory';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COMPANY_HOST_DEVICES_FEATURE_KEY,
  CompanyHostDevicesState,
  companyHostDevicesAdapter,
} from '../reducers';

export const companyHostDevicesState =
  createFeatureSelector<CompanyHostDevicesState>(
    COMPANY_HOST_DEVICES_FEATURE_KEY
  );

const { selectAll } = companyHostDevicesAdapter.getSelectors();

export const isLoading = createSelector(
  companyHostDevicesState,
  (state: CompanyHostDevicesState) => state.isLoading
);

export const companyHostDevices = createSelector(
  companyHostDevicesState,
  (state: CompanyHostDevicesState) =>
    selectAll(state).map((_) => buildCompanyHostDeviceEntity(_))
);
