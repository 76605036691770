import {
  CompanyHostDeviceEntity,
  CompanyHostDeviceId,
  CompanyName,
  companyHostDeviceId,
  companyName,
} from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyHostDeviceEnableResponseDTO {
  private static validation = Validate.compile(
    CompanyHostDeviceEnableResponseDTO.validationSchema(),
    CompanyHostDeviceEntity
  );

  constructor(readonly data: CompanyHostDeviceEnableResponse) {
    Validate.assert(CompanyHostDeviceEnableResponseDTO.validation, data);
  }

  private static validationSchema() {
    return {
      type: 'object',
      required: ['device'],
      properties: {
        device: {
          instanceof: CompanyHostDeviceEntity.name,
        },
      },
    };
  }
}

interface CompanyHostDeviceEnableResponse {
  device: CompanyHostDeviceEntity;
}
