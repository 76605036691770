import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { accountAnonAccessGuard } from '@edgvr-front/account/feature/account-access';
import { HeaderTemplateComponent } from '@edgvr-front/edgvr-front/feature/template';

const routes: Routes = [
  {
    path: '',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./front/front.module').then((m) => m.FrontModule),
      },
    ],
    pathMatch: 'full',
  },
  {
    path: 'sign-in',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./sign-in/sign-in.module').then((m) => m.SignInModule),
      },
    ],
    canActivate: [accountAnonAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'reset-password',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./reset-password/reset-password.module').then(
            (m) => m.ResetPasswordModule
          ),
      },
    ],
    canActivate: [accountAnonAccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
