import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Env, ENV_TOKEN } from './edgvr-front-feature-env.token';

@NgModule({
  imports: [CommonModule],
})
export class EdgvrFrontFeatureEnvModule {
  static forRoot(env: Env): ModuleWithProviders<EdgvrFrontFeatureEnvModule> {
    return {
      ngModule: EdgvrFrontFeatureEnvModule,
      providers: [
        {
          provide: ENV_TOKEN,
          useValue: env,
        },
      ],
    };
  }
}
