import {
  CompanyGeneratedUsageVO,
  CompanyUsageGenerate2Cmd,
} from '@edgvr-front/companies/domain';
import { createAction, props } from '@ngrx/store';

export const companyUsageClean = createAction(
  '[Companies/API] Company Usage Clean'
);

export const companyUsageGenerate = createAction(
  '[Companies/API] Company Usage Generate',
  props<{ companyUsageGenerateCmd: CompanyUsageGenerate2Cmd }>()
);
export const companyUsageGenerateSuccess = createAction(
  '[Companies/API] Company Usage Generate Success',
  props<{ companyGeneratedUsageVO: CompanyGeneratedUsageVO }>()
);
export const companyUsageGenerateFailure = createAction(
  '[Companies/API] Company Usage Generate Failure',
  props<{ error: string }>()
);
