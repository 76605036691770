import {
  CompanyStudentDeviceId,
  CompanyName,
  companyStudentDeviceId,
  companyName,
} from '@edgvr-front/companies/domain';
import { Validate } from '@edgvr-front/util/validate';

export class CompanyStudentDeviceDeleteRequestDTO {
  private static validation = Validate.compile(
    CompanyStudentDeviceDeleteRequestDTO.validationSchema()
  );

  constructor(readonly data: CompanyStudentDeviceDeleteRequest) {
    Validate.assert(CompanyStudentDeviceDeleteRequestDTO.validation, data);
  }

  private static validationSchema() {
    return {
      type: 'object',
      required: ['companyName', 'deviceId'],
      properties: {
        companyName,
        deviceId: companyStudentDeviceId,
      },
    };
  }
}

interface CompanyStudentDeviceDeleteRequest {
  companyName: CompanyName;
  deviceId: CompanyStudentDeviceId;
}
