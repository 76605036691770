import { Validate } from '@edgvr-front/util/validate';
import { CompanyHostDeviceId, CompanyName } from '../interface';
import { companyHostDeviceId, companyName } from '../schema';

export class CompanyHostDeviceDisableVO {
  private static configValidation = Validate.compile(
    CompanyHostDeviceDisableVO.configValidationSchema()
  );

  constructor(readonly value: CompanyHostDeviceDisable) {
    Validate.assert(CompanyHostDeviceDisableVO.configValidation, this.value);
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['companyName', 'deviceId'],
      properties: {
        companyName,
        deviceId: companyHostDeviceId,
      },
      additionalProperties: false,
    };
  }
}

interface CompanyHostDeviceDisable {
  companyName: CompanyName;
  deviceId: CompanyHostDeviceId;
}
