import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  COMPANY_STUDENT_DEVICES_FEATURE_KEY,
  CompanyStudentDevicesState,
  companyStudentDevicesAdapter,
} from '../reducers';
import { buildCompanyStudentDeviceEntity } from '@edgvr-front/companies/util/factory';

export const companyStudentDevicesState =
  createFeatureSelector<CompanyStudentDevicesState>(
    COMPANY_STUDENT_DEVICES_FEATURE_KEY
  );

const { selectAll } = companyStudentDevicesAdapter.getSelectors();

export const isLoading = createSelector(
  companyStudentDevicesState,
  (state: CompanyStudentDevicesState) => state.isLoading
);

export const companyStudentDevices = createSelector(
  companyStudentDevicesState,
  (state: CompanyStudentDevicesState) =>
    selectAll(state).map((_) => buildCompanyStudentDeviceEntity(_))
);
