<span (click)="onRefresh(); (false)" *ngIf="!loaderOnly"
  ><svg
    class="portal-section-title-refresh"
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
  >
    <g clip-path="url(#clip0_1106_3982)">
      <path
        d="M2.968 5.597a.246.246 0 0 0-.186-.408H2.15A2.86 2.86 0 0 1 5 2.144c.757 0 1.446.296 1.957.778l.892-1.043A4.195 4.195 0 0 0 5 .775c-1.129 0-2.19.44-2.988 1.237A4.198 4.198 0 0 0 .78 5.19H.246a.246.246 0 0 0-.185.408l1.201 1.377.252.29.906-1.04.548-.627ZM9.939 4.793l-.896-1.028-.557-.64-.796.914-.658.754a.246.246 0 0 0 .186.408h.631A2.86 2.86 0 0 1 5 7.856a2.842 2.842 0 0 1-1.748-.599L2.36 8.3c.747.6 1.668.925 2.64.925 1.129 0 2.19-.44 2.988-1.237A4.195 4.195 0 0 0 9.22 5.2h.533a.246.246 0 0 0 .185-.408Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1106_3982">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs></svg
></span>
<span *ngIf="!refreshOnly && isLoading">
  <div
    class="d-inline-block align-middle la-ball-beat la-sm"
    [class.la-dark]="dark"
  >
    <div></div>
    <div></div>
    <div></div>
  </div>
</span>
