import { Validate } from '@edgvr-front/util/validate';
import {
  CompanyStudentDeviceId,
  CompanyStudentDeviceModel,
  CompanyStudentDeviceSid,
  CompanyStudentDeviceTitle,
} from '../interface';
import {
  companyStudentDeviceId,
  companyStudentDeviceModel,
  companyStudentDeviceSid,
  companyStudentDeviceTitle,
} from '../schema';

export class CompanyStudentDeviceEntity {
  private static configValidation = Validate.compile(
    CompanyStudentDeviceEntity.configValidationSchema()
  );

  constructor(readonly entity: CompanyStudentDevice) {
    Validate.assert(CompanyStudentDeviceEntity.configValidation, entity);
  }

  toJSON() {
    return this.entity;
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['id', 'title', 'sid', 'model'],
      properties: {
        id: companyStudentDeviceId,
        title: companyStudentDeviceTitle,
        sid: companyStudentDeviceSid,
        model: companyStudentDeviceModel,
      },
      additionalProperties: false,
    };
  }
}

export interface CompanyStudentDeviceEntityJson {
  id: CompanyStudentDeviceId;
  title: CompanyStudentDeviceTitle;
  sid: CompanyStudentDeviceSid;
  model: CompanyStudentDeviceModel;
}

interface CompanyStudentDevice {
  id: CompanyStudentDeviceId;
  title: CompanyStudentDeviceTitle;
  sid: CompanyStudentDeviceSid;
  model: CompanyStudentDeviceModel;
}
