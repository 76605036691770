import {
  CompanyHostDeviceDeleteVO,
  CompanyHostDeviceDisableVO,
  CompanyHostDeviceEnableVO,
  CompanyHostDeviceEntity,
  CompanyHostDevicesListVO,
} from '@edgvr-front/companies/domain';
import { createAction, props } from '@ngrx/store';

export const companyHostDeviceDelete = createAction(
  '[Companies/API] Company Host Device Delete',
  props<{ companyHostDeviceDeleteVO: CompanyHostDeviceDeleteVO }>()
);
export const companyHostDeviceDeleteSuccess = createAction(
  '[Companies/API] Company Host Device Delete Success',
  props<{ companyHostDeviceDeleteVO: CompanyHostDeviceDeleteVO }>()
);
export const companyHostDeviceDeleteFailure = createAction(
  '[Companies/API] Company Host Device Delete Failure',
  props<{ error: string }>()
);

export const companyHostDeviceDisable = createAction(
  '[Companies/API] Company Host Device Disable',
  props<{ companyHostDeviceDisableVO: CompanyHostDeviceDisableVO }>()
);
export const companyHostDeviceDisableSuccess = createAction(
  '[Companies/API] Company Host Device Disable Success',
  props<{ device: CompanyHostDeviceEntity }>()
);
export const companyHostDeviceDisableFailure = createAction(
  '[Companies/API] Company Host Device Disable Failure',
  props<{ error: string }>()
);

export const companyHostDeviceEnable = createAction(
  '[Companies/API] Company Host Device Enable',
  props<{ companyHostDeviceEnableVO: CompanyHostDeviceEnableVO }>()
);
export const companyHostDeviceEnableSuccess = createAction(
  '[Companies/API] Company Host Device Enable Success',
  props<{ device: CompanyHostDeviceEntity }>()
);
export const companyHostDeviceEnableFailure = createAction(
  '[Companies/API] Company Host Device Enable Failure',
  props<{ error: string }>()
);

export const companyHostDevicesList = createAction(
  '[Companies/API] Company Host Devices List',
  props<{ companyHostDevicesListVO: CompanyHostDevicesListVO }>()
);
export const companyHostDevicesListSuccess = createAction(
  '[Companies/API] Company Host Devices List Success',
  props<{ devices: CompanyHostDeviceEntity[] }>()
);
export const companyHostDevicesListFailure = createAction(
  '[Companies/API] Company Host Devices List Failure',
  props<{ error: string }>()
);
