import { buildCompanyGeneratedUsageVO } from '@edgvr-front/companies/util/factory';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COMPANY_USAGE_FEATURE_KEY, CompanyUsageState } from '../reducers';

export const companyState = createFeatureSelector<CompanyUsageState>(
  COMPANY_USAGE_FEATURE_KEY
);

export const usage = createSelector(companyState, (state: CompanyUsageState) =>
  state.usage ? buildCompanyGeneratedUsageVO(state.usage) : state.usage
);

export const isLoading = createSelector(
  companyState,
  (state: CompanyUsageState) => state.isLoading
);
