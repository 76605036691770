import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AccountFacade } from '@edgvr-front/account/data-access';
import { Env, ENV_TOKEN } from '@edgvr-front/edgvr-front/feature/env';
import { map, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'edgvr-front-feature-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  selectedAccount: null | string = null;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(ENV_TOKEN) private readonly env: Env,
    private readonly accountFacade: AccountFacade
  ) {}

  ngOnInit() {
    this.accountFacade.companies$
      .pipe(
        map((_) => (_ ? Object.keys(_) : [])),
        tap((_) => (this.selectedAccount = _[0] ? _[0] : null)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get installer() {
    return this.env.binaries.installer;
  }

  get moduleBuilder() {
    return this.env.binaries.moduleBuilder;
  }

  get contact() {
    return this.env.contact;
  }

  get isLoading$() {
    return this.accountFacade.isLoading$;
  }

  get accounts$() {
    return this.accountFacade.companies$.pipe(
      map((_) => (_ ? Object.keys(_) : null))
    );
  }

  get email$() {
    return this.accountFacade.account$.pipe(map((_) => _?.email || null));
  }

  onReSignIn() {
    this.accountFacade.reSignIn();
  }

  onSignOut() {
    return this.accountFacade.signOut();
  }

  onSelect(e: Event) {
    this.selectedAccount = (e.target as HTMLSelectElement).value;
  }
}
