import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilFilePreviewModule } from '@edgvr-front/util/file-preview';
import Components from './components';

@NgModule({
  declarations: [...Components],
  imports: [CommonModule, UtilFilePreviewModule],
  exports: [...Components],
})
export class UiModule {}
