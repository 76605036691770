import { Timestamp, timestamp } from '@edgvr-front/common/domain';
import { Validate } from '@edgvr-front/util/validate';
import {
  CompanyGeneratedUsageAssessors,
  CompanyGeneratedUsageCandidates,
  CompanyGeneratedUsageFileUrl,
  CompanyGeneratedUsageVoids,
  CompanyGeneratedUsageWorkshops,
} from '../interface';
import {
  companyGeneratedUsageAssessors,
  companyGeneratedUsageCandidates,
  companyGeneratedUsageFileUrl,
  companyGeneratedUsageVoids,
  companyGeneratedUsageWorkshops,
} from '../schema';

export class CompanyGeneratedUsageVO {
  private static configValidation = Validate.compile(
    CompanyGeneratedUsageVO.configValidationSchema()
  );

  constructor(readonly value: CompanyGeneratedUsage) {
    Validate.assert(CompanyGeneratedUsageVO.configValidation, this.value);
  }

  toJSON() {
    return this.value;
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: [
        'dateFrom',
        'assessors',
        'candidates',
        'voids',
        'workshops',
        'fileUrl',
      ],
      properties: {
        dateFrom: timestamp,
        dateTill: timestamp,
        assessors: companyGeneratedUsageAssessors,
        candidates: companyGeneratedUsageCandidates,
        voids: companyGeneratedUsageVoids,
        workshops: companyGeneratedUsageWorkshops,
        fileUrl: companyGeneratedUsageFileUrl,
      },
      additionalProperties: false,
    };
  }
}

export interface CompanyGeneratedUsageJson {
  dateFrom: Timestamp;
  dateTill?: Timestamp;
  workshops: CompanyGeneratedUsageWorkshops;
  assessors: CompanyGeneratedUsageAssessors;
  candidates: CompanyGeneratedUsageCandidates;
  voids: CompanyGeneratedUsageVoids;
  fileUrl: CompanyGeneratedUsageFileUrl;
}

interface CompanyGeneratedUsage {
  dateFrom: Timestamp;
  dateTill?: Timestamp;
  workshops: CompanyGeneratedUsageWorkshops;
  assessors: CompanyGeneratedUsageAssessors;
  candidates: CompanyGeneratedUsageCandidates;
  voids: CompanyGeneratedUsageVoids;
  fileUrl: CompanyGeneratedUsageFileUrl;
}
