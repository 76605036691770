import { InjectionToken } from '@angular/core';

export interface Env {
  production: boolean;
  apiUrl: string;
  frontUrl: string;
  portalUrl: string;
  domain: string;
  tokenName: string;
  sentry: {
    dsn: string;
    env: string;
  };
  binaries: {
    installer: string;
    moduleBuilder: string;
  };
  contact: string;
}

export const ENV_TOKEN = new InjectionToken<Env>('env');
