import { CompanyGeneratedUsageJson } from '@edgvr-front/companies/domain';
import { createReducer, on, Action } from '@ngrx/store';
import { CompanyUsageActions } from '../actions';

export const COMPANY_USAGE_FEATURE_KEY = 'company-usage';

export interface CompanyUsageState {
  usage: null | CompanyGeneratedUsageJson;
  isLoading: boolean;
}

export const initialCompanyUsageState: CompanyUsageState = {
  usage: null,
  isLoading: false,
};

const reducer = createReducer(
  initialCompanyUsageState,
  on(CompanyUsageActions.companyUsageClean, () => initialCompanyUsageState),
  on(CompanyUsageActions.companyUsageGenerate, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    CompanyUsageActions.companyUsageGenerateSuccess,
    (state, { companyGeneratedUsageVO }) => ({
      ...state,
      usage: companyGeneratedUsageVO.toJSON(),
      isLoading: false,
    })
  ),
  on(CompanyUsageActions.companyUsageGenerateFailure, (state) => ({
    ...state,
    isLoading: false,
  }))
);

export function companyUsageReducer(
  state: CompanyUsageState | undefined,
  action: Action
) {
  return reducer(state, action);
}
