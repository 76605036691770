import {
  CompanyStudentDeviceDeleteVO,
  CompanyStudentDeviceEntity,
  CompanyStudentDevicesListVO,
} from '@edgvr-front/companies/domain';
import { createAction, props } from '@ngrx/store';

export const companyStudentDeviceDelete = createAction(
  '[Companies/API] Company Student Device Delete',
  props<{ companyStudentDeviceDeleteVO: CompanyStudentDeviceDeleteVO }>()
);
export const companyStudentDeviceDeleteSuccess = createAction(
  '[Companies/API] Company Student Device Delete Success',
  props<{ companyStudentDeviceDeleteVO: CompanyStudentDeviceDeleteVO }>()
);
export const companyStudentDeviceDeleteFailure = createAction(
  '[Companies/API] Company Student Device Delete Failure',
  props<{ error: string }>()
);

export const companyStudentDevicesList = createAction(
  '[Companies/API] Company Student Devices List',
  props<{ companyStudentDevicesListVO: CompanyStudentDevicesListVO }>()
);
export const companyStudentDevicesListSuccess = createAction(
  '[Companies/API] Company Student Devices List Success',
  props<{ devices: CompanyStudentDeviceEntity[] }>()
);
export const companyStudentDevicesListFailure = createAction(
  '[Companies/API] Company Student Devices List Failure',
  props<{ error: string }>()
);
