import { Validate } from '@edgvr-front/util/validate';
import {
  CompanyHostDeviceEnabled,
  CompanyHostDeviceId,
  CompanyHostDeviceModel,
  CompanyHostDeviceSid,
  CompanyHostDeviceTitle,
} from '../interface';
import {
  companyHostDeviceEnabled,
  companyHostDeviceId,
  companyHostDeviceModel,
  companyHostDeviceSid,
  companyHostDeviceTitle,
} from '../schema';

export class CompanyHostDeviceEntity {
  private static configValidation = Validate.compile(
    CompanyHostDeviceEntity.configValidationSchema()
  );

  constructor(readonly entity: CompanyHostDevice) {
    Validate.assert(CompanyHostDeviceEntity.configValidation, entity);
  }

  toJSON() {
    return this.entity;
  }

  private static configValidationSchema() {
    return {
      type: 'object',
      required: ['id', 'title', 'sid', 'model', 'enabled'],
      properties: {
        id: companyHostDeviceId,
        title: companyHostDeviceTitle,
        sid: companyHostDeviceSid,
        model: companyHostDeviceModel,
        enabled: companyHostDeviceEnabled,
      },
      additionalProperties: false,
    };
  }
}

export interface CompanyHostDeviceEntityJson {
  id: CompanyHostDeviceId;
  title: CompanyHostDeviceTitle;
  sid: CompanyHostDeviceSid;
  model: CompanyHostDeviceModel;
  enabled: CompanyHostDeviceEnabled;
}

interface CompanyHostDevice {
  id: CompanyHostDeviceId;
  title: CompanyHostDeviceTitle;
  sid: CompanyHostDeviceSid;
  model: CompanyHostDeviceModel;
  enabled: CompanyHostDeviceEnabled;
}
